<template>
  <v-container fluid>

  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Acerca de", icon: "mdi-information", path: "/about" },
      { text: "Cerrar sesión", icon: "mdi-door-closed", path: "/close_session" },
    ],
  }),
  mounted() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {},
    newRegister() {
      this.$router.push("/register/new");
    },
  },
};
</script>
